.PageScaffold {
    display: flex;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: white;
    color: black;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 25px;
    clear: both;
    padding-bottom: 100px;
}

.Card {
    flex: 1;
    max-width: 450px;
}