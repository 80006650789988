@import '../variables';

.Footer {
    height: 50px;
    text-align: center;
    background-color: $primary-color;
    position: fixed;
    bottom: 0px;
    width: 100%;
}

.FooterContent{
    color:white;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 100%;
}
